.App {
  text-align: center;
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}
.hero-image {
  max-width: 98%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mask1 {
  padding-top: 25%;
  padding-bottom: 25%;
  width: 100%;
  height: 100%;
  background: url("images/bg2_img.jpg") no-repeat center center/cover;
  mask-image: linear-gradient(to bottom, transparent, #000 60%),
  linear-gradient(to bottom, #000 60%, transparent),
  linear-gradient(to bottom, transparent, #000 60%),
  linear-gradient(to bottom, #000 60%, transparent),
  linear-gradient(to bottom, transparent, #000 60%);
  mask-size: 18% 70%;
  mask-position: 0% 25%, 25% 0%, 50% 25%, 75% 0%, 100% 25%;
  mask-repeat: no-repeat;
  overflow: hidden;
  position: relative;

}
html {
  scroll-behavior: smooth;
}

#bbblinkimg{
  padding: 0px;
  border: none;
  width: 120px;
  height: 108px;
}